import axios from "axios";
import { getGetAxiosInstance, getPostPutAxiosInstance, handleError } from "./utils";

class AtelierAPI {
  async stats() {
    try {
      const response = await getGetAxiosInstance().get("/atelier/stats");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async resetYear() {
    try {
      const response = await getPostPutAxiosInstance().put("/atelier/reset-year");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default AtelierAPI;
