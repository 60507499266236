type KeypadDisplayProps = {
  response: string;
};

const displayResponse = (response: string) => {
  if (!response) return "...";

  return response.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

function KeypadDisplay({ response }: Readonly<KeypadDisplayProps>) {
  return <div dangerouslySetInnerHTML={{ __html: displayResponse(response) }} />;
}

export default KeypadDisplay;
