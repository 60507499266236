import { faBlackboard, faCompress, faDoorOpen, faExpand, faGear, faHouse, faLightbulb, faRightFromBracket, faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink, useMatch } from "react-router-dom";
import Routes from "../routes";
import { useState } from "react";
import { useAuth } from "../contexts/auth";
import { ModalTypeEnum, UserTypeEnum } from "../utils";
import { useModals } from "../contexts/modals";

type HeaderProps = {
  height: number;
};

function Header({ height }: Readonly<HeaderProps>) {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toogleFullScreen = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  const closeHeaderDropdown = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const dropDownMenu = e.currentTarget.parentElement?.parentElement;
    dropDownMenu?.classList.add("no-dropdown-hover");
    setTimeout(() => {
      dropDownMenu?.classList.remove("no-dropdown-hover");
    }, 1);
  };

  return (
    <header className="bg-ternary fixed-top" style={{ height: height }}>
      <div id="navbar">
        <nav className="navbar navbar-expand navbar-dark bg-ternary d-flex justify-content-between">
          <div className="d-flex justify-content-arround w-100" style={{ height: height }} id="navbarPrincipal">
            <ul className="navbar-nav me-auto">
              {/* <li className="nav-item">
                <a href="https://www.mathsenvie.fr/" className="d-flex h-100 p-2">
                  <img className="align-self-center" src="img/logo_small.webp" alt="Site M@ths en-vie" style={{ maxHeight: 30 }} />
                </a>
              </li> */}
              <li className="nav-item">
                <a href={Routes.APPS} className="d-flex btn btn-ternary text-primary rounded-0 h-100">
                  <FontAwesomeIcon className="bg-secondary p-2 rounded-5 align-self-center" size="xl" icon={faDoorOpen} />
                </a>
              </li>
              <li className="nav-item">
                <Link className="d-flex btn btn-ternary text-primary rounded-0 h-100" to={Routes.HOME}>
                  <FontAwesomeIcon className="bg-secondary p-2 rounded-5 align-self-center" icon={faHouse} />
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown">
                <button className="d-flex btn btn-ternary text-primary rounded-0 h-100" data-bs-toggle="dropdown" aria-expanded="false" aria-label="Aides">
                  <FontAwesomeIcon className="bg-secondary p-2 rounded-5 align-self-center" icon={faLightbulb} />
                </button>
                <ul className="dropdown-menu pt-0 pb-0">
                  <li>
                    <NavLink to={Routes.HELP} className="dropdown-item" onClick={closeHeaderDropdown} end>
                      Aide Générale
                    </NavLink>
                    <Link to={{ pathname: Routes.HELP, hash: "atelier" }} className="dropdown-item" onClick={closeHeaderDropdown}>
                      Module "Atelier"
                    </Link>
                    <Link to={{ pathname: Routes.HELP, hash: "en-classe" }} className="dropdown-item" onClick={closeHeaderDropdown}>
                      Module "En classe"
                    </Link>
                    <Link to={{ pathname: Routes.HELP, hash: "a-la-maison" }} className="dropdown-item" onClick={closeHeaderDropdown}>
                      Module "A la maison"
                    </Link>
                    <Link to={{ pathname: Routes.HELP, hash: "tableau-de-bord" }} className="dropdown-item" onClick={closeHeaderDropdown}>
                      Le tableau de bord
                    </Link>
                    <Link to={{ pathname: Routes.HELP, hash: "programmation" }} className="dropdown-item" onClick={closeHeaderDropdown}>
                      La programmation/progression
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <button className="d-flex btn btn-ternary text-primary rounded-0 h-100" onClick={toogleFullScreen}>
                  <FontAwesomeIcon className="bg-secondary p-2 rounded-5 align-self-center" icon={isFullScreen ? faCompress : faExpand} />
                </button>
              </li>
              <UserHeader />
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

function UserHeader() {
  const { user, logout, isInitialLoading } = useAuth();
  const { openModal } = useModals();
  const isDashboardLink = useMatch(Routes.TEACHER_DASHBOARD);

  const loginURL = new URL(Routes.APPS_LOGIN);
  loginURL.searchParams.append("redirect", window.location.origin + (process.env.REACT_APP_BASENAME_ROUTER === "/" ? "" : process.env.REACT_APP_BASENAME_ROUTER) + Routes.HOME);

  return (
    <>
      {isInitialLoading && <FontAwesomeIcon icon={faSpinner} spin size="lg" className="text-white me-4" style={{ marginTop: 15 }} />}
      {user && (
        <li className="nav-item dropdown">
          <button className="d-flex btn btn-ternary text-primary rounded-0 h-100" data-bs-toggle="dropdown" aria-expanded="false" aria-label="Profile">
            <FontAwesomeIcon className="bg-secondary p-2 rounded-5 align-self-center" icon={faUser} />
          </button>
          <ul className="dropdown-menu dropdown-menu-end bg-ternary no-dropdown-hover">
            <p className="p-1 mb-0 text-center">{user.username}</p>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <NavLink className="dropdown-item" to={Routes.PROFIL_PARAMETERS}>
                <FontAwesomeIcon icon={faGear} /> Paramètres
              </NavLink>
            </li>
            <li>
              <button className={`dropdown-item ${isDashboardLink ? "active" : ""}`} onClick={() => openModal(ModalTypeEnum.SudoMode, { userType: UserTypeEnum.Teacher })}>
                <FontAwesomeIcon icon={faBlackboard} /> Tableau de bord
              </button>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <button className="dropdown-item" id="logout" onClick={logout}>
                <FontAwesomeIcon icon={faRightFromBracket} /> Déconnexion
              </button>
            </li>
          </ul>
        </li>
      )}
      {!user && !isInitialLoading && (
        <>
          <li className="nav-item">
            <a href={loginURL.toString()} className="nav-link">
              Se&nbsp;connecter
            </a>
          </li>
          <li className="nav-item">
            <a href={Routes.APPS_REGISTER} className="nav-link">
              S'inscrire
            </a>
          </li>
        </>
      )}
    </>
  );
}

export default Header;
