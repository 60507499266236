import { useEffect } from "react";
import { useBlocker as UseBlockerRouter } from "react-router-dom";
import { useModals } from "../contexts/modals";
import { ModalTypeEnum } from "../utils";

type useBlockerProps = {
  title?: string;
  message?: string;
  when?: boolean;
};

const useBlocker = ({ title = "", message = "", when = false }: useBlockerProps) => {
  const { onModalClose, openModal, closeModal } = useModals();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") return;

    const unloadCallback = (event: BeforeUnloadEvent) => {
      if (when) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      if (process.env.NODE_ENV === "development") return;

      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, [when]);

  const blocker = UseBlockerRouter(({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname && when);

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (!when) {
        blocker.proceed();
        return;
      }

      onModalClose(ModalTypeEnum.Message, () => {
        if (blocker.state === "blocked") {
          blocker.reset();
        }
      });

      openModal(ModalTypeEnum.Message, {
        title: title || "Quitter la page ?",
        content: message || "Êtes-vous sûr de vouloir quitter la page ? Vous risquez de perdre des données.",
        buttons: [
          { label: "Oui", bgColor: "danger", onButtonClick: blocker.proceed },
          { label: "Non", bgColor: "success", onButtonClick: () => closeModal(ModalTypeEnum.Message) },
        ],
      });
    }
  }, [blocker, openModal, onModalClose, closeModal, message, title, when]);
};

export default useBlocker;
