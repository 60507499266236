const StatsKey: [base: string] = ["stats"];

const StudentKey: [base: string] = ["student"];
const StudentsKey: [base: string] = ["students"];
const StudentIdKey = (studentId: number): [base: string, studentId: number] => [...StudentKey, studentId];

export { StatsKey, StudentKey, StudentIdKey, StudentsKey };

export type StatsData = {
  classesCount: number;
  studentsCount: number;
  problemsResolved: number;
};
