import { Modal } from "react-bootstrap";
import { ModalPropsDefault } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

type AnswerModalProps = {
  isCorrectAnswer: boolean;
  answerGiven: string;
};

function AnswerModal({ show, closeModal, isCorrectAnswer, answerGiven }: ModalPropsDefault & AnswerModalProps) {
  return (
    <Modal show={show} onHide={closeModal} backdrop="static" fullscreen animation={false}>
      <Modal.Body className="w-100 h-100 d-flex justify-content-center">
        <div className="text-center align-self-center">
          {isCorrectAnswer ? (
            <p className="fs-1 fw-bold mb-5">Bravo, c'est la bonne réponse</p>
          ) : (
            <p className="fs-1 fw-bold mb-5">
              <span className="mx-1 d-inline-block bg-danger rounded-3 px-2 text-white">{answerGiven.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</span> n'est pas la bonne réponse
            </p>
          )}
          {isCorrectAnswer ? <FontAwesomeIcon className="text-success" icon={faCircleCheck} size="10x" /> : <FontAwesomeIcon className="text-danger" icon={faCircleXmark} size="10x" />}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AnswerModal;
