enum ModalTypeEnum {
  Answer = "answer",
  EditProgrammation = "editProgrammation",
  HelpRequests = "helpRequests",
  Message = "message",
  Message2 = "message2",
  Programmation = "programmation",
  SudoMode = "sudoMode",
  YearReset = "yearReset",
}

export default ModalTypeEnum;
