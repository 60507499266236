import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../hooks";
import { ModalPropsDefault, MutationData } from "../../types";
import { Alert, CloseButton, Modal } from "react-bootstrap";
import FormButtonLoading from "../FormButtonLoading";
import { useModals } from "../../contexts/modals";
import { ModalTypeEnum } from "../../utils";
import { StudentKey, StudentsKey } from "../../pages/query";

function YearResetModal({ show, closeModal }: Readonly<ModalPropsDefault>) {
  const { openModal } = useModals();
  const queryClient = useQueryClient();

  const resetYear = useMutation<MutationData>({
    mutationFn: () => {
      return api.atelier.resetYear();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: StudentsKey });
      queryClient.invalidateQueries({ queryKey: StudentKey });

      openModal(ModalTypeEnum.Message, {
        title: "Nouvelle année !",
        content: "Votre classe est désormais prête pour cette nouvelle année scolaire. Pensez à configurer le niveau de vos élèves.",
        bgColor: "success",
      });
      closeModal();
    },
  });

  return (
    <Modal show={show} onHide={closeModal} size="lg">
      <Modal.Header>
        <h4>Changement d'année</h4>
        <CloseButton variant="white" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {resetYear.isError && <Alert variant={resetYear.error.variant}>{resetYear.error.message}</Alert>}
        {resetYear.isSuccess && <Alert variant="success">{resetYear.data.message}</Alert>}
        <div>
          Attention, cette action est irréversible ! Vous vous apprêtez à effacer toutes les données de vos élèves (niveau, résultats) en vue d'une nouvelle année scolaire. Êtes-vous sûr de vouloir
          continuer ?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={closeModal} className="btn btn-ternary">
          Annuler
        </button>
        <FormButtonLoading className="btn btn-danger" label="Réinitialiser" isPending={resetYear.isPending} onClick={() => resetYear.mutate()} />
      </Modal.Footer>
    </Modal>
  );
}

export default YearResetModal;
