import { CloseButton, Modal } from "react-bootstrap";
import { useUtils } from "../../hooks";
import { ModalPropsDefault } from "../../types";

type MessageModalProps = {
  title: string;
  content: string;
  buttons?: { label: string; bgColor?: string; onButtonClick: () => void }[];
  bgColor?: string;
};

function MessageModal({ show, closeModal, title, content, buttons, bgColor }: ModalPropsDefault & MessageModalProps) {
  const { br, sanitize } = useUtils();

  return (
    <Modal show={show} onHide={closeModal} size="lg">
      <Modal.Header className={`bg-${bgColor}`}>
        <h4>{title}</h4>
        <CloseButton variant="white" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={sanitize(br(content))}></div>
        {buttons && (
          <div className="d-flex gap-2 mt-3 justify-content-end">
            {buttons.map((button) => (
              <button key={button.label} className={`btn btn-${button.bgColor ?? "primary"} fw-bold`} onClick={button.onButtonClick}>
                {button.label}
              </button>
            ))}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default MessageModal;
