import { faArrowLeftLong, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useEffect } from "react";

type NumericKeypadProps = {
  response: string;
  setResponse: Dispatch<SetStateAction<string>>;
  onValid: () => void;
};

function NumericKeypad({ response, setResponse, onValid }: Readonly<NumericKeypadProps>) {
  const updateValue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const nextCaracter = e.currentTarget.innerText;
    setResponse((old) => old.concat(nextCaracter));
  };

  const removeValue = (removeAll: boolean = false) => {
    setResponse((old) => old.slice(0, removeAll ? 0 : -1));
  };

  useEffect(() => {
    const keyUp = (e: KeyboardEvent) => {
      if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ","].includes(e.key)) {
        setResponse((old) => old.concat(e.key));
      } else if (e.key === "Backspace") {
        setResponse((old) => old.slice(0, -1));
      }
    };

    document.addEventListener("keyup", keyUp, false);

    return () => {
      document.removeEventListener("keyup", keyUp, false);
    };
  }, [setResponse]);

  useEffect(() => {
    if (response.length > 12) {
      setResponse((old) => old.slice(0, -1));
    }
  }, [response, setResponse]);

  return (
    <div className="container p-2 rounded-2 border-ternary h-100">
      <div className="d-flex flex-wrap justify-content-between h-100" style={{ rowGap: "1vh" }}>
        <button className="btn btn-primary btn-sm fw-bold w-100" style={{ flexBasis: "32%", fontSize: "3vh" }} onClick={updateValue}>
          1
        </button>
        <button className="btn btn-primary fw-bold w-100" style={{ flexBasis: "32%", fontSize: "3vh" }} onClick={updateValue}>
          2
        </button>
        <button className="btn btn-primary fw-bold w-100" style={{ flexBasis: "32%", fontSize: "3vh" }} onClick={updateValue}>
          3
        </button>
        <button className="btn btn-primary fw-bold w-100" style={{ flexBasis: "32%", fontSize: "3vh" }} onClick={updateValue}>
          4
        </button>
        <button className="btn btn-primary fw-bold w-100" style={{ flexBasis: "32%", fontSize: "3vh" }} onClick={updateValue}>
          5
        </button>
        <button className="btn btn-primary fw-bold w-100" style={{ flexBasis: "32%", fontSize: "3vh" }} onClick={updateValue}>
          6
        </button>
        <button className="btn btn-primary fw-bold w-100" style={{ flexBasis: "32%", fontSize: "3vh" }} onClick={updateValue}>
          7
        </button>
        <button className="btn btn-primary fw-bold w-100" style={{ flexBasis: "32%", fontSize: "3vh" }} onClick={updateValue}>
          8
        </button>
        <button className="btn btn-primary fw-bold w-100" style={{ flexBasis: "32%", fontSize: "3vh" }} onClick={updateValue}>
          9
        </button>
        <button className="btn btn-primary fw-bold w-100" style={{ flexBasis: "32%", fontSize: "3vh" }} onClick={updateValue}>
          0
        </button>
        <button className="btn btn-primary fw-bold w-100" style={{ flexBasis: "32%", fontSize: "3vh" }} onClick={updateValue}>
          /
        </button>
        <button className="btn btn-primary fw-bold w-100" style={{ flexBasis: "32%", fontSize: "3vh" }} onClick={updateValue}>
          ,
        </button>
        <button className="btn btn-secondary fw-bold w-100" style={{ flexBasis: "48%", fontSize: "calc(1vh + 1vw)" }} onClick={() => removeValue()} disabled={!response}>
          <FontAwesomeIcon icon={faArrowLeftLong} size="xl" />
        </button>
        <button className="btn btn-success fw-bold w-100" style={{ flexBasis: "48%", fontSize: "calc(1vh + 1vw)" }} onClick={onValid} disabled={!response}>
          <FontAwesomeIcon icon={faCheck} size="xl" />
        </button>
      </div>
    </div>
  );
}

export default NumericKeypad;
