import { GameAPI, UserAPI, StudentAPI, HelpRequestAPI, ProgrammationAPI, ProfilAPI, AtelierAPI } from "./apis";

const api = {
  atelier: new AtelierAPI(),
  game: new GameAPI(),
  helpRequest: new HelpRequestAPI(),
  programmation: new ProgrammationAPI(),
  profil: new ProfilAPI(),
  user: new UserAPI(),
  student: new StudentAPI(),
};

export default api;
